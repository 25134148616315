export function disableScroll() {
  if (!isScrollDisabled()) {
    document.documentElement.style.overflow = 'hidden';
  }
}

export function enableScroll() {
  if (isScrollDisabled()) {
    document.documentElement.style.overflow = 'unset';
  }
}

export function isScrollDisabled() {
  return document.documentElement.style.overflow === 'hidden';
}

export const scrollToTop = () => {
  window && window.scroll({ top: 0 });
};

export const restoreScrollPosition = (url: string) => {
  sessionStorage.setItem(`scrollPos:${url}`, JSON.stringify({ x: 0, y: 0 }));
};

export const scrollElementIntoView = ({
  element,
  shouldFocus = true,
  scrollIntoViewOptions,
}: {
  element: HTMLElement;
  shouldFocus?: boolean;
  scrollIntoViewOptions?: ScrollIntoViewOptions;
}) => {
  const prefersReducedMotion = window.matchMedia(
    '(prefers-reduced-motion: reduce)',
  );
  const behavior = prefersReducedMotion.matches ? 'auto' : 'smooth';
  element.scrollIntoView({ behavior, ...scrollIntoViewOptions });
  if (shouldFocus) {
    element.focus({ preventScroll: true });
  }
};
